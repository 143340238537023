
export default {
  name: 'JbLogin',
  props: {
    redirect: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    showPassword: false,
    busy: false,
    model: {
      email: null,
      password: null
    }
  }),
  computed: {
    email: vm => vm.$route.query.email,
    validEmail: vm => !!String(vm.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ),
    hideEmail: vm => !!vm.email && vm.validEmail
  },
  created () {
    if (this.hideEmail) {
      this.model.email = this.email
    }
  },
  methods: {
    async onSubmit () {
      this.busy = true
      try {
        await this.$auth.loginWith('laravelSanctum', {
          data: {
            email: this.model.email,
            password: this.model.password,
            'g-recaptcha-response': await this.$recaptcha.execute('login')
          }
        })

        await this.$bvModal.hide('modal-login')

        if (this.redirect) {
          if (this.$store.state.loginRedirectRoute) {
            this.$router.push(this.$store.state.loginRedirectRoute)
          } else if (this.$store.getters.loginRedirectRoute) {
            this.$router.push(this.$store.getters.loginRedirectRoute)
          } else {
            alert('store loginRedirectRoute missing')
          }
        } else {
          this.$root.$emit('refresh')
        }
      } catch (e) {
        this.busy = false
      }
    },
    getValidationState ({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? (valid ? null : false) : null
    },
    onHidden () {
      // Return focus to the button once hidden
      this.$refs.button.focus()
    }
  }
}
