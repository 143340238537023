
export default {
  name: 'JbNav',
  methods: {
    async logout () {
      await this.$auth.logout()
      this.$router.push('/')
    }
  }
}
