
export default {
  name: 'JbSidebarItem',
  props: {
    navItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    isShowing: false
  }),
  computed: {
    showing: vm => vm.navItem.navItems && vm.navItem.navItems.some(navItem => navItem.to === vm.$route.path)
  },
  watch: {
    showing (newVal) {
      this.$set(this, 'isShowing', newVal)
    }
  },
  mounted () {
    this.$nextTick(function () {
      this.$set(this, 'isShowing', this.showing)
    })
  }
}
